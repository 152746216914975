.wmde-markdown hr {
  overflow: hidden;
  height: unset;
  padding: 0;
  margin: 24px 0;
  background-color: none;
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #e6e8f0;
  border-bottom-width: thin;
}

.wmde-markdown h2,
.wmde-markdown h1 {
  border-bottom: none;
}

.markdown-container > h1,
.markdown-container > h2,
.markdown-container > h3,
.markdown-container > p {
  margin-bottom: 0 !important;
}

.ql-size-huge {
  font-size: 32px;
}

.ql-size-large {
  font-size: 24px;
}

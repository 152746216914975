.ql-editor {
  min-height: 144px;
}

.quill {
  border: 1px solid #d1d5db;
  border-radius: 8px;
}
.ql-container.ql-snow {
  border: none;
}

.ql-toolbar.ql-snow {
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
  border-bottom: 1px solid #d1d5db;
}

.text-editor-wrapper {
  position: relative;
}
.text-editor-label {
  background-color: #ffffff;
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  font-family: 'DM Sans';
  top: -5px;
  left: 14px;
  padding: 0 4px;
}
